import { QUERY_KEY_CONSTANTS } from "src/constants/queryKey";
import { useRequest } from "src/contexts/request";
import { checkShouldExecuteQuery } from "src/utils/queryData/checkShouldExecuteQuery";
import { useFetchData } from "src/hooks/utils/useDataQuery";

export const useFetchVerifyProductQuery = ({ config }) => {
  const { get, STATION_HOSTNAME } = useRequest();

  const shouldExecuteQuery = checkShouldExecuteQuery(config);

  const _getVerifyProduct = async () => {
    try {
      const {
        json: { data }
      } = await get(`${STATION_HOSTNAME}/verify-product`);

      return data;
    } catch (error) {
      throw error;
    }
  };

  return useFetchData(
    shouldExecuteQuery && [QUERY_KEY_CONSTANTS.GE_VERIFY_PRODUCT],
    _getVerifyProduct,
    {
      refetchOnWindowFocus: false,
      ...config
    }
  );
};

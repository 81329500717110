import React from "react";
import { useVerifyProduct } from "src/hooks/useVerifyProduct";

const VerifyProductContext = React.createContext({});

export const VerifyProductContextProvider = ({
  shouldFetchVerifyProduct,
  children
}) => {
  useVerifyProduct(shouldFetchVerifyProduct);

  return (
    <VerifyProductContext.Provider>{children}</VerifyProductContext.Provider>
  );
};

export const useVerifyProductContext = () =>
  React.useContext(VerifyProductContext);

import React from "react";

import { Flex, IconButton } from "@chakra-ui/react";
import Logo from "../Logo";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useRouter } from "next/router";
import { useGlobalValuesContext } from "src/contexts/globalValues";
import { NavigationBarUserInfo } from "./Fragments/NavigationBarUserInfo";
import { UsageTrackingSection } from "./Fragments/UsageTrackingSection";

const Header = () => {
  const router = useRouter();
  const showResetBanner = router.pathname === "/home";
  const { showBanner, setBannerState } = useGlobalValuesContext();

  const handleToggleOnboarding = () => setBannerState(true);

  return (
    <Flex
      as="nav"
      data-testid="navbar"
      h="3.5em"
      w="100%"
      borderBottom="solid 1px"
      borderColor="mandala.black.300"
      position="fixed"
      top="0"
      backgroundColor="primary"
      align="center"
      zIndex={3}
    >
      <Flex w="calc(100% - 550px)" justify="space-between" align="center">
        <Logo h="40px" ml="1.5em" mt=".25em" useWhiteLogo />
        {showResetBanner && (
          <IconButton
            color="white"
            fontWeight="700"
            fontSize="xl"
            rounded="full"
            mr="1em"
            icon={<FaRegQuestionCircle />}
            data-testid="show-banner-button"
            isDisabled={showBanner}
            _hover={{ bg: "transparent" }}
            _disabled={{ bg: "transparent" }}
            bgColor="transparent"
            variant="transparent"
            onClick={handleToggleOnboarding}
          />
        )}
      </Flex>
      <Flex w="450px" minW="350px">
        <UsageTrackingSection />
      </Flex>
      <Flex w="250px" borderLeft="solid 1px" borderColor="mandala.black.300">
        <NavigationBarUserInfo />
      </Flex>
    </Flex>
  );
};

export default Header;

import React from "react";
import { useAuthContext } from "src/contexts/auth";
import { FiLogOut } from "react-icons/fi";
import {
  Portal,
  MenuList,
  MenuItem as Item,
  Text,
  Icon,
  Link
} from "@chakra-ui/react";
import { AiOutlineMonitor, AiOutlineSetting } from "react-icons/ai";
import { IoWarningOutline } from "react-icons/io5";
import { useRouter } from "next/router";
import { useUserStatusContext } from "src/contexts/users/userStatus";

const MenuItem = ({
  Icon: IconComponent,
  onClick,
  title,
  href = "",
  ...props
}) => {
  return (
    <Item
      p=".75em"
      fontSize="xl"
      bgColor="white"
      cursor="pointer"
      border="none"
      borderBottom="solid 1px"
      borderColor="mandala.black.300"
      onClick={onClick}
      href={href}
      as={Link}
      variant="transparent"
      _hover={{
        bgColor: "mandala.blue.200"
      }}
      {...props}
    >
      <Icon as={IconComponent} mr=".75em" />
      <Text color={props.color} fontSize="sm">
        {title}
      </Text>
    </Item>
  );
};

export const NavigationBarMenu = () => {
  const { logout: handleClickLogout } = useAuthContext();
  const { push } = useRouter();
  const {
    userStatusData: { isFreeUser }
  } = useUserStatusContext();

  const URLSetting = `/settings/${
    !isFreeUser ? "storage-usage" : "change-password"
  }`;

  return (
    <Portal>
      <MenuList
        data-testid="user-setting-dropdown-item"
        zIndex={5}
        bgColor="white"
        border="solid 1px"
        borderColor="mandala.black.300"
        boxShadow="md"
        pos="absolute"
        p="0"
      >
        <MenuItem
          borderTopRadius=".25em"
          data-testid="user-setting-dropdown-item-settings"
          href={URLSetting}
          Icon={AiOutlineSetting}
          title="Settings"
          onClick={e => {
            e.preventDefault();
            push(URLSetting);
          }}
        />
        <MenuItem
          data-testid="user-setting-dropdown-item-monitoring"
          href="/monitoring"
          Icon={AiOutlineMonitor}
          title="Monitoring"
          onClick={e => {
            e.preventDefault();
            push("/monitoring");
          }}
        />
        <MenuItem
          data-testid="user-setting-dropdown-item-report-problem"
          href="mailto:support@delman.io?subject=%5BMandala%20Support%5D%20Please%20enter%20your%20subject"
          Icon={IoWarningOutline}
          title="Report Problems"
        />
        <MenuItem
          borderBottomRadius=".25em"
          href="/login"
          data-testid="user-setting-dropdown-item-logout"
          onClick={e => {
            e.preventDefault();
            handleClickLogout();
          }}
          Icon={FiLogOut}
          title="Logout"
          color="mandala.red.500"
        />
      </MenuList>
    </Portal>
  );
};

export const QUERY_KEY_CONSTANTS = {
  GET_CHARTS_BY_DASHBOARD_ID: "GET_CHARTS_BY_DASHBOARD_ID",

  GET_EXPORT_HISTORY: "GET_EXPORT_HISTORY",

  GET_NODE_PROCESS: "GET_NODE_PROCESS",

  GET_LIST_OF_TRANSFORMATION_ACTIONS: "GET_LIST_OF_TRANSFORMATION_ACTIONS",

  GET_CUSTOM_ACTION_DETAILS: "GET_CUSTOM_ACTION_DETAILS",

  GET_EXPORT_OPTIONS: "GET_EXPORT_OPTIONS",

  GET_AVAILABLE_FIELDS: "GET_AVAILABLE_FIELDS",

  GET_DATABSE_CONFIG: "GET_DATABSE_CONFIG",

  GET_MOCKED_FEATURE_TABLE: "GET_MOCKED_FEATURE_TABLE",

  GET_DROPDOWN_DYNAMIC_FETCHING: "GET_DROPDOWN_DYNAMIC_FETCHING",

  GET_LIST_OF_ACTION_SCRIPT: "GET_LIST_OF_ACTION_SCRIPT",

  GET_AUDIENCE_INFORMATION: "GET_AUDIENCE_INFORMATION",

  GET_CDP_AUDIENCE_PREVIEW: "GET_CDP_AUDIENCE_PREVIEW",

  GET_CDP_LIST: "GET_CDP_LIST",

  GET_CDP_FIELD: "GET_CDP_FIELD",

  GET_PROJECT_DETAILS_INFORMATION: "GET_PROJECT_DETAILS_INFORMATION",

  GET_CHART_LIST: "GET_CHART_LIST",

  GET_LIST_OF_COMBINE_SCRIPT: "GET_LIST_OF_COMBINE_SCRIPT",

  GET_CUSTOM_CONNECTOR_CONTENT: "GET_CUSTOM_CONNECTOR_CONTENT",

  GET_DASHBOARD_CONFIG: "GET_DASHBOARD_CONFIG",

  GET_LIST_OF_DASHBOARD: "GET_LIST_OF_DASHBOARD",

  GET_CONFIG_PARAMS: "GET_CONFIG_PARAMS",

  GET_EXTERNAL_CONNECTIONS: "GET_EXTERNAL_CONNECTIONS",

  GET_IDENTITY_CREATOR: "GET_IDENTITY_CREATOR",

  GET_REFERENCE_STATE_DATA: "GET_REFERENCE_STATE_DATA",

  // Identity Match
  GET_ID_MATCH_NEXT_REF_ID: "GET_ID_MATCH_NEXT_REF_ID",
  GET_ID_MATCH_TOP_RESULTS: "GET_ID_MATCH_TOP_RESULTS",
  GET_INFINITE_ID_MATCH_OVERVIEW_DATA: "GET_INFINITE_ID_MATCH_OVERVIEW_DATA",

  GET_SELECTED_REGISTERED_ID_MATCH_RECORD:
    "GET_SELECTED_REGISTERED_ID_MATCH_RECORD",

  GET_NODE_INFORMATION: "GET_NODE_INFORMATION",

  GET_SELECTED_NODE: "GET_SELECTED_NODE",

  GET_RECIPE_INFORMATION: "GET_RECIPE_INFORMATION",

  GET_AVAILABLE_SCHEMAS_OR_DATASETS: "GET_AVAILABLE_SCHEMAS_OR_DATASETS",

  GET_AVERAGE_STORAGE_USAGE: "GET_AVERAGE_STORAGE_USAGE",

  GET_CDP_CATEGORY_TYPE: "GET_CDP_CATEGORY_TYPE",

  GET_CDP_IDENTITY_PREVIEW: "GET_CDP_IDENTITY_PREVIEW",

  GET_SOURCE_DATA: "GET_SOURCE_DATA",

  GET_TABLE_ADDER_PREVIEW: "GET_TABLE_ADDER_PREVIEW",

  GET_CDP_EXISTING_DATA_PREVIEW: "GET_CDP_EXISTING_DATA_PREVIEW",

  GET_COMBINE_DETAILS: "GET_COMBINE_DETAILS",

  GET_CHART_DATA: "GET_CHART_DATA",

  GET_CHART_VISUALIZATION_DATA: "GET_CHART_VISUALIZATION_DATA",

  GET_STATE_DATA_WITH_SEARCH: "GET_STATE_DATA_WITH_SEARCH",

  GET_STORAGE_BREAKDOWN: "GET_STORAGE_BREAKDOWN",

  GET_CHART_BY_ID: "GET_CHART_BY_ID",

  GET_LIST_OF_AVAILABLE_SINKS: "GET_LIST_OF_AVAILABLE_SINKS",

  // onboarding
  GET_ONBOARDING_SECTION_DETAILS: "GET_ONBOARDING_SECTION_DETAILS",

  // data repository related
  GET_LIST_DATASETS: "GET_LIST_DATASETS",
  GET_INFINITE_DATASET_DATA: "GET_INFINITE_DATASET_DATA",

  // standardize
  GET_INFINITE_CLUSTER_DATA: "GET_INFINITE_CLUSTER_DATA",
  GET_INFINITE_CLUSTER_DATA_PREVIEW: "GET_INFINITE_CLUSTER_DATA_PREVIEW",
  GET_INFINITE_CLUSTER_DATA_BY_ID: "GET_INFINITE_CLUSTER_DATA_BY_ID",

  GET_CUSTOM_CONNECTOR_LIST: "GET_CUSTOM_CONNECTOR_LIST",
  GET_PROJECT_INFORMATION_PAGE_CHART: "GET_PROJECT_INFORMATION_PAGE_CHART",
  GET_PROJECT_INFORMATION_PAGE_DASHBOARD:
    "GET_PROJECT_INFORMATION_PAGE_DASHBOARD",

  // SQL QUERY
  GET_PROJECT_INFORMATION_PAGE_SQL_QUERY:
    "GET_PROJECT_INFORMATION_PAGE_SQL_QUERY",
  GET_LIST_OF_QUERY_HISTORY: "GET_LIST_OF_QUERY_HISTORY",
  GET_QUERY_STATUS: "GET_QUERY_STATUS",

  // Param Config
  GET_ACTION_PARAMETERS: "GET_ACTION_PARAMETERS",

  // Get Projects
  GET_LIST_OF_PROJECTS: "GET_LIST_OF_PROJECTS",
  GET_PUBLISHED_NODE: "GET_PUBLISHED_NODE",
  GET_PUBLISH_CONNECTION_LIST: "GET_PUBLISH_CONNECTION_LIST",

  // Get share access permission
  GET_LIST_OF_ROLE_TO_SHARE: "GET_LIST_OF_ROLE_TO_SHARE",
  GET_LIST_OF_SHARED_ACCESS: "GET_LIST_OF_SHARED_ACCESS",
  GET_LIST_OF_USERS_TO_SHARE: "GET_LIST_OF_USERS_TO_SHARE",

  // Get Monitoring
  GET_GLOBAL_MONITORING_LIST: "GET_GLOBAL_MONITORING_LIST",
  GET_PROJECT_MONITORING_LIST: "GET_PROJECT_MONITORING_LIST",
  GET_MONITORING_DETAILS: "GET_MONITORING_DETAILS",
  GET_MONITORING_LOGS: "GET_MONITORING_LOGS",

  // Process
  GET_PROCESS_TYPE: "GET_PROCESS_TYPE",
  GET_PROCESS_STATUS: "GET_PROCESS_STATUS",

  // Project Details
  GET_VIEW_DATA_PROJECT_DETAILS: "GET_VIEW_DATA_PROJECT_DETAILS",

  // Responsible for Rendering Nodes and Edges
  GET_LIST_OF_PROJECT_NODES: "GET_LIST_OF_PROJECT_NODES",
  GET_LIST_OF_NODE_NEXT_BY_PROJECT: "GET_LIST_OF_NODE_NEXT_BY_PROJECT",

  // import connector
  GET_IMPORT_DATA_PARAM_CONFIG: "GET_IMPORT_DATA_PARAM_CONFIG",
  GET_LIST_OF_IMPORTED_DATA: "GET_LIST_OF_IMPORTED_DATA",
  GET_LIST_OF_IMPORT_HISTORY: "GET_LIST_OF_IMPORT_HISTORY",

  // S3
  GET_S3_BUCKET_LIST: "GET_S3_BUCKET_LIST",
  GET_S3_BUCKET_OBJECT: "GET_S3_BUCKET_OBJECT",
  GET_S3_AVAILABLE_SHEETS_LIST: "GET_S3_AVAILABLE_SHEETS_LIST",

  // User Status
  GET_USER_STATUS: "GET_USER_STATUS",

  // Verify Product
  GET_VERIFY_PRODUCT: "GET_VERIFY_PRODUCT",

  // Preview Data
  GET_NODE_DATA_BY_STATE_ID: "GET_NODE_DATA_BY_STATE_ID",
  GET_NODE_DATA_BY_VERSION_HISTORY: "GET_NODE_DATA_BY_VERSION_HISTORY",
  GET_PREVIEW_DATA_BY_STATE_ID: "GET_PREVIEW_DATA_BY_STATE_ID",

  GET_TABLE_SUMMARY: "GET_TABLE_SUMMARY",

  // process usage
  GET_BILLING_CYCLE: "GET_BILLING_CYCLE",
  GET_PROCESSING_TIME_USAGE: "GET_PROCESSING_TIME_USAGE",

  // schedule sync
  GET_LIST_OF_SCHEDULE_SYNC: "GET_LIST_OF_SCHEDULE_SYNC",
  GET_LIST_OF_REPORT_SYNC: "GET_LIST_OF_REPORT_SYNC",

  // Billing
  GET_BILLING_SUMMARY: "GET_BILLING_SUMMARY",
  GET_BILLING_DETAIL: "GET_BILLING_DETAIL",

  // Nodes
  GET_LIST_OF_NODES: "GET_LIST_OF_NODES",

  // Merge Data Preview
  GET_MERGE_PREVIEW_DATA: "GET_MERGE_PREVIEW_DATA",
  GET_NODE_DETAILS_TRANSFER_NODE: "GET_NODE_DETAILS_TRANSFER_NODE"
};

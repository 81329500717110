import React from "react";
import { Link, Image } from "@chakra-ui/react";

const Logo = ({
  isLink = true,
  useWhiteLogo = false,
  linkProps = {},
  ...props
}) => {
  const logoUrl = useWhiteLogo ? "/images/logo.png" : "/images/logo.png";
  if (isLink) {
    return (
      <Link href="/home" variant="transparent" {...linkProps}>
        <Image alt="" src={logoUrl} {...props} />
      </Link>
    );
  }
  return <Image alt="" src={logoUrl} {...props} />;
};

export default Logo;

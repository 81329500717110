import { REACT_QUERY_STATUS } from "src/constants/reactQueryStatus";
import { useFetchVerifyProductQuery } from "./queries/fetches/useFetchVerifyProductQuery";
import { useRouter } from "next/router";

/**
 * This hook will handle everything that related to user status
 * (e.g: isCDPUser and isFreeUser) by fetching into GET /users/status.
 * @returns {*}
 */

// Notes: on we implement SSR Fetch Data the result of "_getUserStatus" should be passed as a props and then we will store it on localstorage
export const useVerifyProduct = shouldFetchVerifyProduct => {
  const { push } = useRouter();

  const { data, status: userStatusRequestStatus } = useFetchVerifyProductQuery({
    config: {
      enabled: shouldFetchVerifyProduct,
      onError: () => {
        push("/verify-product");
      }
    }
  });

  const isLoading = userStatusRequestStatus === REACT_QUERY_STATUS.LOADING;
  const isSuccess = userStatusRequestStatus === REACT_QUERY_STATUS.SUCCESS;
  const isError = userStatusRequestStatus === REACT_QUERY_STATUS.ERROR;

  return {
    data,
    isLoading,
    isSuccess,
    isError
  };
};

import React from "react";
import { Box } from "@chakra-ui/react";
import NavigationBar from "../MandalaUI/NavigationBar";
import { useRouter } from "next/router";
import {
  UserStatusContextProvider,
  useUserStatusContext
} from "src/contexts/users/userStatus";
import { LimitQuotaBanner } from "./Fragments/LimitQuotaBanner";
import { VerifyProductContextProvider } from "src/contexts/verifyProduct";

const PAGE_WITHOUT_NAVBAR_LIST = [
  "/login",
  "/signup",
  "/verify-account/[userId]",
  "/forgot-password"
];

const PAGE_WITHOUT_AUTH = [
  "/signup",
  "/verify-account/[userId]",
  "/forgot-password"
];

const NAVBAR_HEIGHT = "3.5em";
const BANNER_HEIGHT = "2.875em";

// This component handles everything inside our app body, hence app content
const AppContent = ({ children, ...props }) => {
  const { showQuotaLimit, isUserInformationStatusFetched } =
    useUserStatusContext();

  // Give body height accordingly whenever sticky limit banner is shown
  const contentHeight =
    isUserInformationStatusFetched && showQuotaLimit
      ? `calc(100% - ${BANNER_HEIGHT})`
      : "100%";

  return (
    <Box h={contentHeight} data-testid={props["data-testid"]} {...props}>
      <LimitQuotaBanner />
      {children}
    </Box>
  );
};

// This component handles the overall page wrapper for ddl-app
const AppShell = ({ children, ...props }) => {
  const router = useRouter();
  const hasNavbar = !PAGE_WITHOUT_NAVBAR_LIST.includes(router.pathname);
  const shouldFetchVerifyProduct = PAGE_WITHOUT_AUTH.includes(router.pathname);

  return (
    <VerifyProductContextProvider
      shouldFetchVerifyProduct={shouldFetchVerifyProduct}
    >
      <UserStatusContextProvider shouldFetchUserStatus={hasNavbar}>
        <Box data-testid="appshell" h="inherit">
          {hasNavbar && <NavigationBar h={NAVBAR_HEIGHT} />}
          <AppContent pt={hasNavbar ? NAVBAR_HEIGHT : "0"} {...props}>
            {children}
          </AppContent>
        </Box>
      </UserStatusContextProvider>
    </VerifyProductContextProvider>
  );
};

export default AppShell;
